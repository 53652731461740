import React, {useState, useEffect} from 'react';
import './App.css';
import {Link} from 'react-router-dom';

function Item({match}) {

  useEffect(() => {
      fetchItem();
  }, []);

  const [item, setItem] = useState({
      images: {}
  });

  const fetchItem = async () => {
    const data = await fetch(`https://fortnite-api.theapinetwork.com/item/get?id=${match.params.id}`);

    const item = await data.json();
    setItem(item.data);
    console.log(item.data);
  }

  return (
    <div>
      <h1>{item.itemId}</h1>
    </div>
  );
}

export default Item;